
export default function BrokenPlank ({destroying, destroyedPlanks, index}) {
    return (
        <div style={{width:0,height:0,position:"absolute"}}>
        <div className={destroying?"BrokenPlank":""}
            style={{position:"relative",height: '18vh', width:'18vh', backgroundColor:"#010109", clipPath:"polygon(99.98% 51.37%,79.25% 56.66%,95.65% 70.4%,74.48% 67.35%,84.37% 86.31%,65.98% 75.39%,67.86% 96.7%,55.04% 79.57%,48.63% 99.98%,43.34% 79.25%,29.6% 95.65%,32.65% 74.48%,13.69% 84.37%,24.61% 65.98%,3.3% 67.86%,20.43% 55.04%,0.02% 48.63%,20.75% 43.34%,4.35% 29.6%,25.52% 32.65%,15.63% 13.69%,34.02% 24.61%,32.14% 3.3%,44.96% 20.43%,51.37% 0.02%,56.66% 20.75%,70.4% 4.35%,67.35% 25.52%,86.31% 15.63%,75.39% 34.02%,96.7% 32.14%,79.57% 44.96%)"}}>
        </div>
        
        </div>
        
    )
}