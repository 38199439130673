import { getItemStyle, random, range } from "./ArenaHelperFunctions"
import BrokenPlank from "./BrokenPlank"
import Card from "./Card"
import '../../Styles/Smoke.css'
import Wave from "react-wavify"

export default function Planks ({resetSelections, canRemove, highlightedCards, setHighlightedCards, selectedPlankCard, setSelectedPlankCard, sink, opponent, viewing, onRemoved, arenaPositions, setCurrentCard, destroyedPositions, destroyingPositions}) {
    const cardClick = (card, index) => {
        if (canRemove) setSelectedPlankCard({card:card, index:index})
    }
    const onHighlighted = (card, index) => {
        if (!Object.keys(card).length>0) return
        if (!card.heavy || !canRemove) return
        if (!highlightedCards.map(item=>item.index).includes(index)) setHighlightedCards([...highlightedCards,{card:card, index:index}])
        else setHighlightedCards(highlightedCards.filter(item=>item.index!==index))
    }
    return (
        <div>
            <div style={{transform: !viewing?"perspective(500vh) rotateX(55deg) translateY(-80vh) translateZ(10vh)":"perspective(500vh) rotateX(25deg) translateY(-105vh) translateZ(-80vh)", transition: "transform 1s", position:"relative"}}>
                <div className={"ArenaPlankOpponent"+(opponent?.sink?"Sink":"")}  style={{zIndex:1}}>
                <div className="ShipContain"><div className="ShipBackOpp"/><div className="ShipBodyBackOpp"/><div className="ShipBodyBackDoorOpp"/><div className="UpperShipDeckOpp"/><div className="ShipFrontOpp"/></div>
                    {opponent?.arenaPositions?.map((cards, index)=>
                            {
                                if (opponent?.destroyedPositions?.map(num=>7-num)?.includes(index)) {
                                    return (
                                        <div  key={index} style={{position:"relative", width:"18vh", height:"18vh"}}>
                                        <BrokenPlank destroying={opponent?.destroyingPositions?.map(num=>7-num)?.includes(index)}/>
                                        <div className="smokeLocation">
                                                <div className="smokeSubLocation">
                                                    <div className="smoke">
                                                    {
                                                            range(18).map(arenaIndex=>{
                                                                const indexRandom = random(index+400+10*arenaIndex)
                                                                const indexRandom2 = random(index+500+10*arenaIndex)
                                                                const randomSize = 6.5+2*indexRandom
                                                                return <span key={arenaIndex} className={`s${arenaIndex}`} style={{border: `${randomSize}vh solid #010109`,borderRadius: `${randomSize}vh`,animation:`smoke${indexRandom>.5?"R":"L"}${indexRandom2>.75?"R":(indexRandom2>.5?"L":"")} 18s ${arenaIndex+indexRandom}s infinite`}}></span>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                const card = cards[0]
                                return cards.length>0?
                                    (<div 
                                        className={"skewCardPersp"+(opponent?.sink?"Sink":"")} 
                                        key={index} 
                                        style={{cursor: "none",height: '24vh', width:'100%',zIndex:viewing?1200:(card?.cardSinked||card?.cardAttacking || opponent?.sink)?5:0}}>
                                        <div style={{...getItemStyle(false, {}),cursor:"none"}}>
                                                <Card {...card} setCurrentCard={setCurrentCard} opp={true} placed={true}/>
                                        </div>
                                    </div>)
                                    :
                                    (<div 
                                        key={index} 
                                        style={{height: '24vh', width:'100%'}}>
                                    </div>)
                            }
                    )}
                    <div className="ShipContain"><div className="ShipBodyOpp" style={{zIndex:viewing?0:(sink?0:2), transition:"transform 1s", transform:viewing?"rotateX(20deg) scaleY(.65) translateX(0.55vh) scaleX(.969) translateY(-6vh)":"scaleY(1)"}}/></div>
                </div>
                
                <div className={"ArenaPlank"+(sink?"Sink":"")} style={{zIndex:1}}>
                <div className="ShipContain"><div className="ShipBack"/><div className="ShipBodyBack" style={{zIndex:viewing?0:1}}/><div className="ShipBodyBackDoor"/><div className="UpperShipDeck"/><div className="ShipFront" style={{zIndex:viewing?0:(sink?0:1)}}/></div>
                    {arenaPositions?.map((cards, index)=>
                            {
                                if (destroyedPositions?.includes(index)) {
                                    return (
                                        <div key={index} style={{position:"relative", width:"18vh", height:"18vh"}}>
                                        <BrokenPlank destroying={opponent?.destroyingPositions?.map(num=>7-num)?.includes(index)}/>
                                        <div className="smokeLocation">
                                                <div className="smokeSubLocation">
                                                    <div className="smoke">
                                                    {
                                                            range(18).map(arenaIndex=>{
                                                                const indexRandom = random(index+100+10*arenaIndex)
                                                                const indexRandom2 = random(index+200+10*arenaIndex)
                                                                const randomSize = 6.5+2*indexRandom
                                                                return <span key={arenaIndex} className={`s${arenaIndex}`} style={{border: `${randomSize}vh solid #010109`,borderRadius: `${randomSize}vh`,animation:`smoke${indexRandom>.5?"R":"L"}${indexRandom2>.75?"R":(indexRandom2>.5?"L":"")} 18s ${arenaIndex+indexRandom}s infinite`}}></span>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                const card = cards[0]
                                return cards.length>0?(
                                <div className={"skewCardPersp"+(sink?"Sink":"")} key={index} style={{cursor: card.heavy?"url(/trash.png),no-drop":"url(/recycle.png),alias",height: '24vh', width:'100%',zIndex:(card?.cardSinked||card?.cardAttacking || sink)?5:0}}>
                                    <div className="SubCard" style={getItemStyle(false, {})}>
                                            <Card {...card} highlighted={highlightedCards.map(item=>item.index).includes(index)} onHighlighted={(e)=>{e.preventDefault();if (!card.cardSinked&&!sink) {onHighlighted(card,index)}}} setCurrentCard={setCurrentCard} onRemoved={()=>{if (!card.cardSinked&&!sink) {cardClick(card,index)}}} placed={true} />
                                        </div></div>):(<div key={index} style={{height: '24vh', width:0}}></div>)
                            }
                    )}
                    <div className="ShipContain" style={{zIndex:1}}><div className="ShipBody" style={{zIndex:viewing?1200:(sink?0:1), transition:"transform 1s", transform:viewing?"rotateX(20deg) scaleY(.65) translateX(-0.55vh) scaleX(.969) translateY(-6vh)":"scaleY(1)"}}/></div>
                    <div style={{zIndex:90, position:"absolute",top:"0", height:0, width:"180vw"}}>
                <Wave fill='#98b3b3'
                paused={false}
                style={{ display: 'flex', height: '120vh',position: "relative", top: `${viewing?45:56}vh`, transition: "top 1s linear", overflowY:"hidden", zIndex: 0}}
                options={{
                    amplitude: 15,
                    speed: 0.25,
                    points: 18
                }}
            />
                    </div>
                </div>
            </div>
            <div className="CardShopBackgroundOuter" style={{display: !(selectedPlankCard?.card?.title)?"none":"", position:"absolute",top:"-104vh", zIndex:525}}></div>
            <div className="CardShopBackgroundInner" style={{display: !(selectedPlankCard?.card?.title)?"none":"", borderRadius:"5vh",position:"absolute", zIndex:650, top:"-71vh", left:0, right:0, margin:"auto", width: "36vh", height:"24vh", backgroundColor:"white"}}></div>
            <div className="CardShopBackgroundCards" style={{display: !(selectedPlankCard?.card?.title)?"none":"", position:"absolute", zIndex:651, top:"-71vh", left:0, right:0, margin:"auto"}}>
                <div style={{display: !(selectedPlankCard?.card?.title)?"none":"", width:"28vh",position:"relative", top:"4vh", left:0, right:0, margin:"auto", fontWeight:"800", fontSize:'1.75vh'}}>{selectedPlankCard?.card?.heavy?`Trade ${highlightedCards.map(item=>item.index).includes(selectedPlankCard.index)?"highlighted cards":"this card"} for ${highlightedCards.map(item=>item.index).includes(selectedPlankCard.index)?highlightedCards.map(item=>item.card.worth*(item.card.level)).reduce((partialSum, a) => partialSum + a, 0):(selectedPlankCard?.card?.worth*(selectedPlankCard?.card?.level))} shell${(highlightedCards.map(item=>item.index).includes(selectedPlankCard.index)?highlightedCards.map(item=>item.card.worth*(item.card.level)).reduce((partialSum, a) => partialSum + a, 0):(selectedPlankCard?.card?.worth*(selectedPlankCard?.card?.level)))>1?"s":""}?`:"Move card to deck?"}</div>
                <div className="RedButtonSmall" style={{position:"absolute", right:0, left:"-18vh", margin:"auto", top:'12vh',width:"12vh", fontSize:'2vh', lineHeight:"4vh", fontWeight:"800"}} onClick={()=>{resetSelections()}}>No</div>
                <div className="SignInButtonSmall" style={{position:"absolute",right:"-18vh", left:0, margin:"auto", top:'12vh', fontSize:'2vh', lineHeight:"4vh",width:"12vh", fontWeight:"800"}} onClick={()=>{onRemoved(highlightedCards.map(item=>item.index).includes(selectedPlankCard.index)?highlightedCards:selectedPlankCard.card, selectedPlankCard.index);resetSelections()}}>Yes</div>
            </div>
        </div>
    )
}