import { allCards, getAttack, getProduction, getWeight } from "../../Data/Card/Cards"

import { colors, convertToRoman } from "./Card"
import { images } from "../../Data/Card/CardImages"
export default function BigCard ({smaller=false, setCurrentCard, card, onRemoved=()=>{}}) {
    const patternGetLink = /(?:linkTo\()(.*?)(?:\))/gm
    function getDescription() {
        const prod = getProduction([card])
        const data = patternGetLink.exec(card.description(prod, card.turnsLeft))
        if (!data?.[0]) return card.description(prod, card.turnsLeft)
        const textSections = card.description(prod, card.turnsLeft).split(data[0])
        return (
            <p>
                <span>{textSections[0]}</span>
                <span onClick={()=>setCurrentCard(prev=>{
                    return [
                        ...prev,
                        allCards()[data[1]]()
                    ].reduce((accumulator, currentValue)=>{
                        return (accumulator.map(item=>item.title).includes(currentValue.title))?(accumulator):([...accumulator, currentValue])
                    }
                ,[])})} style={{color:"#6655ff",textDecoration: "underline",cursor:"url(/card-info.png),default"}}>{data[1]}</span>
                <span>{textSections[1]}</span>
            </p>
        )
    }
    return (
        <>
        <style>{`
                .CardTintspell {
                    background-color: ${colors.spell[0]};
                    background-blend-mode: multiply;
                }
                    .CardTinttroop {
                    background-color: ${colors.troop[0]};
                    background-blend-mode: multiply;
                }
                    .CardTintbuilding {
                    background-color: ${colors.building[0]};
                    background-blend-mode: multiply;
                }

                `}</style>
        {
            card?.title?
            <div className={'CardBig'+" CardTint"+card.cardType} style={{backgroundColor:colors[card.cardType][0],height:!smaller?'50vh':'40vh', width:!smaller?'30vh':'24vh'}}>
                        <div className="CardFaceBig" onClick={onRemoved}>
                            <div style={{height:'1vh'}}/>
                            <div className="Type" style={{color: colors[card.cardType][1]}}>{card.cardType}</div>
                            <div style={{height:'.2vh'}}/>
                            <div className="Title" style={{color: colors[card.cardType][1]}}>{card.title}</div>
                            <div style={{height:'.25vh'}}/>
                            <div className="Level" style={{fontFamily:"Serif", fontSize:'2.5vh',color: colors[card.cardType][1]}}>{card.level!==1?convertToRoman(card.level):""}</div>
                            <div style={{height:'0vh'}}/>
                            <div className="Image" style={{color: colors[card.cardType][1], paddingLeft:'.75vh'}}>{images[card.title]()}</div>
                            <div style={{height:'1vh'}}/>
                            <div className="Description" style={{color: colors[card.cardType][1], position:"absolute", top:!smaller?'15vh':'13vh',left:'0vh', width:'15vh'}}>{getDescription()}</div>
                        </div>
                        <div className="StickersBig" style={{top:'-17.5vh', left:'2vh',position:"relative",zIndex:0, filter:"contrast(40%) brightness(130%)"}}>
                            {
                                (Object.values(card.floatie??{}).reduce((partialSum, a) => partialSum + a, 0))<1?
                                <></>
                                :
                                <div onClick={card.onRemoved?card.onRemoved:()=>{}} onContextMenu={card.onHighlighted?card.onHighlighted:()=>{}} className="pickUpBadgeBig" style={{zIndex:2, color: colors[card.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                                    <div style={{zIndex:0}}>{images["Pile of Floaties Icon"]()}</div>
                                </div>
                            }
                            {
                                (Object.values(card.ladybug??{}).reduce((partialSum, a) => partialSum + a, 0))<1?
                                <></>
                                :
                                <div onClick={card.onRemoved?card.onRemoved:()=>{}} onContextMenu={card.onHighlighted?card.onHighlighted:()=>{}} className="pickUpBadgeBig" style={{zIndex:2, color: colors[card.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                                    <div style={{zIndex:0}}>{images["Ladybug Icon"]()}</div>
                                </div>
                            }
                            {
                                (Object.values(card.beetle??{}).reduce((partialSum, a) => partialSum + a, 0))<1?
                                <></>
                                :
                                <div onClick={card.onRemoved?card.onRemoved:()=>{}} onContextMenu={card.onHighlighted?card.onHighlighted:()=>{}} className="pickUpBadgeBig" style={{zIndex:2, color: colors[card.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                                    <div style={{zIndex:0}}>{images["Beetle Icon"]()}</div>
                                </div>
                            }
                        </div>
                        <div className="AttibutesBig" style={{display:'grid', gridTemplateColumns:'repeat(3,4.25vh)', position:'relative',top:'20vh',paddingLeft:'1.5vh'}}>
                            <div>
                                <div className="Unit" style={{color: colors[card.cardType][1]}}>Weight</div>
                                <div className="Weight" style={{border:"1px solid "+ colors[card.cardType][1]}}><div className="WeightCircle" style={{color: colors[card.cardType][1]}}>{getWeight([card])}</div></div>
                            </div>
                            <div>
                            <div className="Unit" style={{color: colors[card.cardType][1]}}>Attack</div>
                                <div className="Attack" style={{border:"1px solid "+ colors[card.cardType][1]}}><div className="AttackCircle" style={{color: colors[card.cardType][1]}}>{card.attack===Number.MAX_SAFE_INTEGER?"?":getAttack([card])}</div></div>
                            </div>
                            <div>
                            <div className="Unit" style={{color: colors[card.cardType][1]}}>Health</div>
                                <div className="Health" style={{border:"1px solid "+ colors[card.cardType][1]}}><div className="HealthCircle" style={{color: colors[card.cardType][1]}}>{card.health===Number.MAX_SAFE_INTEGER?"?":(card.health)}</div></div>
                            </div>
                            
                        </div>
                        {card.heavy?
                        <>{
                            (card.worth*card.level)<1?
                            <></>
                            :
                        <div className="pickUpBadgeBig" style={{position:"relative", top:'-27.5vh', left:'2vh', color: colors[card.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                            <img alt="shell worth" style={{height:'1.75vh', width:'1.75vh'}} src="https://cdn-icons-png.freepik.com/512/79/79842.png"/>
                            <div style={{position:"relative", top:'-1.75vh',left:".375vh", fontSize:"1vh", lineHeight:".95vh", borderRadius:"50%",width:"1.1vh",height:"1.1vh", backgroundColor:colors[card.cardType][0]}}>{(card.worth*(card.level))}</div>
                            <div className="pickUpBadgeVerbage" style={{backgroundColor:colors[card.cardType][0], width:'8vh', borderRadius:'1vh', position:"relative", top:'-4.45vh',left:'2.5vh', fontSize:'1vh', fontWeight:"800"}}>Worth {(card.worth*(card.level))} Shell{(card.worth*(card.level))>1?"s":""}</div>
                        </div>
                        }</>
                        :
                        <div className="pickUpBadgeBig" style={{position:"relative", top:'-28vh', left:'2vh', color: colors[card.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                            <script src='https://kit.fontawesome.com/a076d05399.js' crossOrigin='anonymous'></script>
                            <i className='fas fa-external-link-alt'></i>
                            <div className="pickUpBadgeVerbage" style={{backgroundColor:colors[card.cardType][0], width:'8vh', borderRadius:'1vh', position:"relative", top:'-2.95vh',left:'2.5vh', fontSize:'1vh', fontWeight:"800"}}>Returns To Deck</div>
                        </div>
                        }
                        
                        
                    </div>
                    :
                    <></>
        }
        </>
    )
}