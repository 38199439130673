import { Component } from "react";

import '../../Styles/Card.css'
import { images } from "../../Data/Card/CardImages";
import { getAttack, getWeight } from "../../Data/Card/Cards";

export const colors={
    spell: ["#cc8888","#774444"],
    troop: ["#77cc99","#337755"],
    building: ["#ddaa44","#886600"],
}

export const convertToRoman = (num) => {
    let roman = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1
    };
    let str = '';
  
    for (let i of Object.keys(roman)) {
      let q = Math.floor(num / roman[i]);
      num -= q * roman[i];
      str += i.repeat(q);
    }
  
    return str;
  }

export default class Card extends Component {
    constructor(props) {
        super(props);
        this.getClassName = this.getClassName.bind(this);
    }
    
    getClassName = () =>{
        return "Card"+((this.props.cardSinked??false)?("Sunk"+((this.props.cardAttacking && !this.props.opp)?"cardAttacking":"")+((this.props.cardAttacking && this.props.opp)?"cardOppAttacking":"")):(""+((this.props.cardAttacking && !this.props.opp)?"cardAttacking":"")+((this.props.cardAttacking && this.props.opp)?"cardOppAttacking":"")+((this.props.cardDamaged)&& !this.props.opp?"cardDamaged":"")+((this.props.cardDamaged)&& this.props.opp?"cardOppDamaged":"")))+" CardTint"+this.props.cardType
    }
    render(){
        return(
            <>
            <style>{`
                .CardTintspell {
                    background-color: ${colors.spell[0]};
                    background-blend-mode: multiply;
                }
                    .CardTinttroop {
                    background-color: ${colors.troop[0]};
                    background-blend-mode: multiply;
                }
                    .CardTintbuilding {
                    background-color: ${colors.building[0]};
                    background-blend-mode: multiply;
                }

                `}</style>
            <div className={this.getClassName(this.props)} style={{zIndex:12,outline:(this.props.highlighted&&!this.props.cardSinked)?"1vh solid #eaea54":""}}>
                <div style={{zIndex:2,position:"relative", height:0,width:0, left:'10vh', opacity:.5}}><div style={{ width:'2vh', height:'2vh',position:"relative", bottom:'1vh'}}><button className="InfoButton" style={{cursor:"url(/card-info.png),default",padding:0, fontSize:'1.5vh', width:'2.25vh', height:'2.25vh',}} onClick={()=>{this.props.setCurrentCard(prev=>[...prev,this.props])}} >i</button></div></div>
                <div className="CardFace" style={{zIndex:1}} onClick={this.props.onRemoved?this.props.onRemoved:()=>{}} onContextMenu={this.props.onHighlighted?this.props.onHighlighted:()=>{}}>
                    <div style={{fontFamily:"Serif",position:"relative", top:'13.5vh', left:'0vh',color: colors[this.props.cardType][1], width:0, height:0}}>
                        <div style={{width:'12vh', fontSize:'2.5vh',position:"relative", top:'-9.5vh',height:"2.5vh"}}>{this.props.level!==1?convertToRoman(this.props.level):""}</div>
                    </div>
                    <div className="Type" style={{color: colors[this.props.cardType][1]}}>{this.props.cardType}</div>
                    <div className="Title" style={{color: colors[this.props.cardType][1]}}>{this.props.title}</div>
                    
                    <div className="Description" style={{color: colors[this.props.cardType][1]}}>{this.props.viewing?this.props.description(this.props.production, this.props.turnsLeft, this.props.plural):images[this.props.title]()}</div>
                </div>
                <div style={{top:'-17.5vh', left:'.5vh',position:"relative",zIndex:2, filter:"contrast(40%) brightness(130%)"}}>
                    {
                        (Object.values(this.props.floatie??{}).reduce((partialSum, a) => partialSum + a, 0))<1?
                        <></>
                        :
                        <div onClick={this.props.onRemoved?this.props.onRemoved:()=>{}} onContextMenu={this.props.onHighlighted?this.props.onHighlighted:()=>{}} className="pickUpBadgeBig" style={{zIndex:2, color: colors[this.props.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                            <div style={{zIndex:0}}>{images["Pile of Floaties Icon"]()}</div>
                        </div>
                    }
                    {
                        (Object.values(this.props.ladybug??{}).reduce((partialSum, a) => partialSum + a, 0))<1?
                        <></>
                        :
                        <div onClick={this.props.onRemoved?this.props.onRemoved:()=>{}} onContextMenu={this.props.onHighlighted?this.props.onHighlighted:()=>{}} className="pickUpBadgeBig" style={{zIndex:2, color: colors[this.props.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                            <div style={{zIndex:0}}>{images["Ladybug Icon"]()}</div>
                        </div>
                    }
                    {
                        (Object.values(this.props.beetle??{}).reduce((partialSum, a) => partialSum + a, 0))<1?
                        <></>
                        :
                        <div onClick={this.props.onRemoved?this.props.onRemoved:()=>{}} onContextMenu={this.props.onHighlighted?this.props.onHighlighted:()=>{}} className="pickUpBadgeBig" style={{zIndex:2, color: colors[this.props.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                            <div style={{zIndex:0}}>{images["Beetle Icon"]()}</div>
                        </div>
                    }
                </div>
                <div onClick={this.props.onRemoved?this.props.onRemoved:()=>{}} onContextMenu={this.props.onHighlighted?this.props.onHighlighted:()=>{}} style={{display:'grid', gridTemplateColumns:'repeat(3,4.25vh)', position:'relative',top:'-1.5vh'}}>
                    <div>
                        <div className="Unit" style={{zIndex:2,color: colors[this.props.cardType][1]}}>Weight</div>
                        <div className="Weight" style={{zIndex:2,border:"1px solid "+ colors[this.props.cardType][1]}}><div className="WeightCircle" style={{color: colors[this.props.cardType][1]}}>{getWeight([{...this.props}])}</div></div>
                    </div>
                    <div>
                    <div className="Unit" style={{zIndex:2,color: colors[this.props.cardType][1]}}>Attack</div>
                        <div className="Attack" style={{zIndex:2,border:"1px solid "+ colors[this.props.cardType][1]}}><div className="AttackCircle" style={{color: colors[this.props.cardType][1]}}>{this.props.attack===Number.MAX_SAFE_INTEGER?"?":getAttack([{...this.props}])}</div></div>
                    </div>
                    <div>
                    <div className="Unit" style={{zIndex:2,color: colors[this.props.cardType][1]}}>Health</div>
                        <div className="Health" style={{zIndex:2,border:"1px solid "+ colors[this.props.cardType][1]}}><div className="HealthCircle" style={{color: colors[this.props.cardType][1]}}>{this.props.health===Number.MAX_SAFE_INTEGER?"?":(this.props.health)}</div></div>
                    </div>
                    
                </div>
                {this.props.heavy?
                <>{
                    (this.props.worth*this.props.level)<1?
                    <></>
                    :
                    <div onClick={this.props.onRemoved?this.props.onRemoved:()=>{}} onContextMenu={this.props.onHighlighted?this.props.onHighlighted:()=>{}} className="pickUpBadgeBig" style={{zIndex:2,position:"relative", top:'-24.4vh', left:'.5vh', color: colors[this.props.cardType][1], fontSize:'1.5vh', width:'0', height:'0'}}>
                        <img alt="shell worth" style={{height:'1vh', width:'1vh'}} src="https://cdn-icons-png.freepik.com/512/79/79842.png"/>
                        <div style={{position:"relative", top:'-1.25vh',left:".15vh", fontSize:".75vh", lineHeight:".7vh", borderRadius:"50%",width:".75vh",height:".75vh", backgroundColor:colors[this.props.cardType][0]}}>{(this.props.worth*(this.props.level))}</div>
                    </div>
                }</>
                :
                <div onClick={this.props.onRemoved?this.props.onRemoved:()=>{}} onContextMenu={this.props.onHighlighted?this.props.onHighlighted:()=>{}} style={{zIndex:2,position:"relative", top:'-23.5vh', left:'-4.5vh', color: colors[this.props.cardType][1], fontSize:'1.5vh'}}>
                    <script src='https://kit.fontawesome.com/a076d05399.js' crossOrigin='anonymous'></script>
                    <i className='fas fa-external-link-alt'></i>
                </div>
                }
            </div>
            </>
        )
    }
}